import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import {formatDate, formatEuro, formatFloat} from '../helpers.js';
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {COLORS} from "../styles";


function StyledHeaderCell(props) {
    return (
        <TableCell
            align='center'
            colSpan={props.colSpan ? props.colSpan : 1}
            style={{
                padding: "0px 0px",
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                borderLeft: "1px solid black",
                backgroundColor: "#5B9BD5",
                fontSize: "13px",
                color: "#FFF"
            }}><b>{props.children}</b></TableCell>
    );
}

function EnhancedTableHead(props) {
    const {classes, order, orderBy, onRequestSort} = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <StyledHeaderCell>#</StyledHeaderCell>
                <StyledHeaderCell>Last change</StyledHeaderCell>
                <StyledHeaderCell colSpan={props?.phases.length+5}>Actuals</StyledHeaderCell>
                <StyledHeaderCell colSpan={props?.phases.length+3}>EtC(€)</StyledHeaderCell>
                <StyledHeaderCell colSpan={2}>Total hours</StyledHeaderCell>
                <StyledHeaderCell>Total mtrl</StyledHeaderCell>
                <StyledHeaderCell>Total cost</StyledHeaderCell>
                <StyledHeaderCell>Comment</StyledHeaderCell>
            </TableRow>
            <TableRow>
                <StyledHeaderCell />
                <StyledHeaderCell />
                <StyledHeaderCell colSpan={props?.phases.length+3}>Hours (hr)</StyledHeaderCell>
                <StyledHeaderCell colSpan={2}>Mtrl(€)</StyledHeaderCell>
                <StyledHeaderCell colSpan={props?.phases.length+2}>Hours (hr)</StyledHeaderCell>
                <StyledHeaderCell>Mtrl(€)</StyledHeaderCell>
                <StyledHeaderCell>Forecast</StyledHeaderCell>
                <StyledHeaderCell>Forecast(€)</StyledHeaderCell>
                <StyledHeaderCell>Forecast</StyledHeaderCell>
                <StyledHeaderCell>Forecast</StyledHeaderCell>
                <StyledHeaderCell />
            </TableRow>
            <TableRow>
                <StyledHeaderCell />
                <StyledHeaderCell />
                <StyledHeaderCell align='center'>Corr</StyledHeaderCell>

                {props.phases ? props.phases.map((phase) =>
                    <StyledHeaderCell align='center'>{phase.ProjectFaseOmschrijving ? phase.ProjectFaseOmschrijving.substring(0, 3) : ""}</StyledHeaderCell>
                    ) : <></>}
                <StyledHeaderCell align='center'>Total</StyledHeaderCell>
                <StyledHeaderCell align='center'>Total(€)</StyledHeaderCell>
                <StyledHeaderCell align='center'>AFAS</StyledHeaderCell>
                <StyledHeaderCell align='center'>Fledge</StyledHeaderCell>
                {props.phases ? props.phases.map((phase) =>
                    <StyledHeaderCell align='center'>{phase.ProjectFaseOmschrijving ? phase.ProjectFaseOmschrijving.substring(0, 3) : ""}</StyledHeaderCell>
                ) : <></>}
                <StyledHeaderCell align='center'>Total</StyledHeaderCell>
                <StyledHeaderCell align='center'>Total(€)</StyledHeaderCell>
                <StyledHeaderCell />
                <StyledHeaderCell />
                <StyledHeaderCell />
                <StyledHeaderCell />
                <StyledHeaderCell />
                <StyledHeaderCell />
            </TableRow>
        </TableHead>
);
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


function StyledCell(props) {
    return (
        <TableCell
            align='center'
            colSpan={props.colSpan ? props.colSpan : 1}
            style={{
                borderTop: "0px solid black",
                borderRight: "0px solid black",
                borderBottom: "0px solid black",
                borderLeft: "0px solid black",
                padding: "2px 2px",
                fontSize: "13px",
                backgroundColor: props.backgroundColor ? props.backgroundColor : "#FFF"
            }}>{props.children}</TableCell>
    );
}


function TableRowImpl(props) {
    const row = props.row;
    const labelId = `enhanced-table-checkbox-${row.id}`;

    const handleHide = () => {
        flaskAPI.delete('/api/demconv/projecten/entries/' + row.id).then(response => {
            if (response.status === 200) {
                props.onShouldUpdate()
            }
        }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                // setAuthTokens();
            }
        })
    }

    const act_hrs = row?.act_hrs_json.reduce((partialSum, a) => partialSum + formatFloat(a?.Aantal), 0);
    const act_hrs_eur = row?.act_hrs_json.reduce((partialSum, a) => partialSum + formatFloat(a?.AantalEur), 0)
    const etc_hrs = row?.etc_hrs_json.reduce((partialSum, a) => partialSum + formatFloat(a?.Aantal), 0);
    const etc_hrs_eur = row?.etc_hrs_json.reduce((partialSum, a) => partialSum + formatFloat(a?.AantalEur), 0)

    return (
        <React.Fragment>
            <TableRow key={row.torh_num} >
                <StyledCell>
                    <IconButton aria-label="delete" onClick={() => handleHide()} disabled={row.is_hidden}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </StyledCell>
                <StyledCell align='right'>{row.last_change ? formatDate(row.last_change) : ''}</StyledCell>

                <StyledCell align='right'>{
                    row?.act_hrs_json.find(x => x.Projectfase == "corr")?.Aantal.toFixed(0)
                }</StyledCell>

                {props.phases ? props.phases.map((phase) =>
                    <StyledCell align='right' backgroundColor={COLORS.lightGreen}>{
                        row?.act_hrs_json.find(x => x.Projectfase == phase.Projectfase)?.Aantal.toFixed(0)
                    }</StyledCell>
                ) : <></>}



                <StyledCell align='right' backgroundColor={COLORS.green}>{act_hrs.toFixed(0)}</StyledCell>
                <StyledCell align='right' backgroundColor={COLORS.green}>{formatEuro(act_hrs_eur)}</StyledCell>
                <StyledCell align='right' backgroundColor={COLORS.lightBlue}>{formatEuro(row?.act_mtrl_afas)}</StyledCell>
                <StyledCell align='right' backgroundColor={COLORS.lightBlue}>{formatEuro(row?.act_mtrl_fledge)}</StyledCell>

                {props.phases ? props.phases.map((phase) =>
                    <StyledCell align='right' backgroundColor={COLORS.lightGreen}>{
                        row?.etc_hrs_json.find(x => x.Projectfase == phase.Projectfase)?.Aantal
                    }</StyledCell>
                ) : <></>}

                <StyledCell align='right' backgroundColor={COLORS.green}>{etc_hrs}</StyledCell>
                <StyledCell align='right' backgroundColor={COLORS.green}>{formatEuro(etc_hrs_eur)}</StyledCell>
                <StyledCell align='right' backgroundColor={COLORS.lightBlue}>{formatEuro(row?.etc_mtrl)}</StyledCell>

                <StyledCell align='right' backgroundColor={COLORS.green}>{(act_hrs + etc_hrs).toFixed(0)}</StyledCell>
                <StyledCell align='right' backgroundColor={COLORS.green}>{formatEuro(act_hrs_eur + etc_hrs_eur)}</StyledCell>
                <StyledCell align='right' backgroundColor={COLORS.lightBlue}>{formatEuro(formatFloat(row?.act_mtrl_afas) + formatFloat(row.etc_mtrl))}</StyledCell>
                <StyledCell align='right' backgroundColor={COLORS.red}>{formatEuro(formatFloat(act_hrs_eur) + formatFloat(etc_hrs_eur) + formatFloat(row?.act_mtrl_afas) + formatFloat(row?.etc_mtrl))}</StyledCell>
                <StyledCell align='right'>{row.comment}</StyledCell>
            </TableRow>
        </React.Fragment>
    );
}

function NewTableRowImpl(props) {
    const {setAuthTokens} = useAuth();
    const [actHrsCorr, setActHrsCorr] = useState(0);
    const [phasesEtc, setPhasesEtc] = useState({});
    const [etcEurMtrl, setEtcEurMtrl] = useState(0);
    const [comment, setComment] = useState('');

    const handleActHrsCorrChange = (event) => {
        let newVal = event.target.value=='' ? '' : event.target.value=='0' ? 0 : event.target.value=='-' ? '-' : parseFloat(event.target.value)
        setActHrsCorr(newVal);
    };
    const handlePhaseEtcChange = (event) => {
        let newVal = event.target.value=='' ? '' : event.target.value=='0' ? 0 : parseFloat(event.target.value)
        let newState = {...phasesEtc, [event.target.name]: newVal}
        setPhasesEtc(newState)
    };
    const handleEurMtrlChange = (event) => {
        let newVal = event.target.value=='' ? '' : event.target.value=='0' ? 0 : parseFloat(event.target.value)
        setEtcEurMtrl(newVal);
    };
    const handleCommentChange = (event) => { setComment(event.target.value); };


    const handleAdd = () => {
        let mappedEtc = Object.entries(phasesEtc).map(([k,v]) => {
           return {
               Aantal: v,
               AantalEur: v*props.financialRate,
               Projectfase: k,
           }
        });

        let newData = {
            project: props.project,
            act_hrs_json: [{Aantal: actHrsCorr, AantalEur: actHrsCorr*props.financialRate, Projectfase: "corr"}, ...phases],
            act_mtrl_afas: actMtrl?.afas,
            act_mtrl_fledge: actMtrl?.fledge,
            etc_hrs_json: mappedEtc,
            etc_mtrl: etcEurMtrl,
            comment: comment,
            last_change: selectedDate
        }

        flaskAPI.post('/api/demconv/projecten/entries', newData).then(response => {
            if (response.status === 201) {
                props.onShouldUpdate()
            }
        }).catch(error => {
            if (error && error.response && error.response.status === 401) {
                setAuthTokens();
            }
        })
    }
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [dateError, setDateError] = useState(false);
    const handleDateChange = (date) => {
        if(isValidDate(date)) {
            setDateError(false);
            setSelectedDate(date);
        } else {
            setDateError(true);
        }
    };

    const classes = useStyles();
    const [phases, setPhases] = useState([])

    useEffect(() => {
        flaskAPI.get('/api/demconv/projecten/phases/' + props.project + '?date_till=' + selectedDate.toISOString().split('T')[0])
            .then(response => {
                setPhases(response.data);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens, selectedDate]);

    const [actMtrl, setActMtrl] = useState({})
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        flaskAPI.get('/api/demconv/projecten/' + props.project + '?date_till=' + selectedDate.toISOString().split('T')[0])
            .then(response => {
                setLoading(false);
                setActMtrl({
                    'afas': response.data.act_afas_eur_mtrl,
                    'fledge': response.data.act_fledge_eur_mtrl
                })
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens, selectedDate]);

    const act_hrs = phases ? phases.reduce((partialSum, a) => partialSum + formatFloat(a?.Aantal), 0) : 0.0;
    const act_hrs_eur = phases ? phases.reduce((partialSum, a) => partialSum + formatFloat(a?.AantalEur), 0) : 0.0;

    return (
        <React.Fragment>
            <TableRow key={props.project} >
                <StyledCell />
                <StyledCell align='right'>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            className={classes.datepicker}
                            error={dateError}
                            id="date-picker-inline"
                            value={selectedDate}
                            onChange={handleDateChange}
                            inputProps={{style: {fontSize: 11}}}
                            // style={{width: '12ch'}}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <Button size="small" variant="outlined" color="primary" onClick={() => handleAdd()} disabled={dateError}>Add</Button>
                </StyledCell>
                <StyledCell align='right'>{<TextField id="act-hrs-corr" inputProps={{style: {fontSize: 11}}} style={{width: '5ch'}} size="small" value={actHrsCorr} onChange={handleActHrsCorrChange} />}</StyledCell>
                {phases ? phases.map((phase) =>
                    <StyledCell align='right' backgroundColor={COLORS.lightGreen}>{formatFloat(phase?.Aantal)}</StyledCell>
                ) : <></>}
                <StyledCell align='right' backgroundColor={COLORS.green}>{formatFloat(act_hrs)}</StyledCell>
                <StyledCell align='right' backgroundColor={COLORS.green}>{formatEuro(act_hrs_eur)}</StyledCell>
                <StyledCell align='right' backgroundColor={COLORS.lightBlue}>{formatEuro(actMtrl?.afas)}</StyledCell>
                <StyledCell align='right' backgroundColor={COLORS.lightBlue}>{formatEuro(actMtrl?.fledge)}</StyledCell>
                {props.phases ? props.phases.map((phase) =>
                    <StyledCell align='right' backgroundColor={COLORS.lightGreen}>{<TextField id="etc-hrs-eng" inputProps={{style: {fontSize: 11}}} style={{width: '5ch'}} size="small" name={phase?.Projectfase} value={(phasesEtc[phase?.Projectfase]==0 || phasesEtc[phase?.Projectfase]) ? phasesEtc[phase?.Projectfase] : ''} onChange={handlePhaseEtcChange} />}</StyledCell>
                ) : <></>}
                <StyledCell backgroundColor={COLORS.green} />
                <StyledCell backgroundColor={COLORS.green} />
                <StyledCell align='right' backgroundColor={COLORS.lightBlue}>{<TextField id="etc-eur-mtrl" inputProps={{style: {fontSize: 11}}} style={{width: '8ch'}}size="small" value={etcEurMtrl} onChange={handleEurMtrlChange} />}</StyledCell>

                <StyledCell backgroundColor={COLORS.green} />
                <StyledCell backgroundColor={COLORS.green} />
                <StyledCell backgroundColor={COLORS.lightBlue} />
                <StyledCell backgroundColor={COLORS.red} />
                <StyledCell align='right'>{<TextField id="etc-eur-mtrl" inputProps={{style: {fontSize: 11}}} style={{width: '30ch'}} size="small" value={comment} onChange={handleCommentChange} />}</StyledCell>
            </TableRow>
        </React.Fragment>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    datepicker: {
        "& .MuiInputBase-root": {
            padding: 0,
            width: 150,
            "& .MuiButtonBase-root": {
                padding: 5,
                paddingLeft: 0
            },
            "& .MuiInputBase-input": {
                padding: 5,
                paddingLeft: 0
            }
        }
    }
}));

function isValidDate(date) {
    return date instanceof Date && !isNaN(date);
}

export default function EnhancedTable(props) {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();
    const [entries, setEntries] = useState([])
    const [shouldUpdate, setShouldUpdate] = useState(0)
    const [showHidden, setShowHidden] = useState(false)
    const filteredEntries = showHidden ? entries : entries.filter(d => d["is_hidden"] == false)
    const [phases, setPhases] = useState([])

    useEffect(() => {
        flaskAPI.get('/api/demconv/projecten/phases/' + props.project)
            .then(response => {
                setPhases(response.data);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);


    useEffect(() => {
        setEntries([]);
        flaskAPI.get('/api/demconv/projecten/entries/' + props.project)
            .then(response => {
                setEntries(response.data);
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens, shouldUpdate]);


    return (
        <div className={classes.root}>
            <FormControlLabel
                control={<Switch checked={showHidden} onChange={(event) => setShowHidden(event.target.checked)}
                                 name="showHiddenSwitch"/>}
                label="Show deleted"
            />

            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size='small'
                    aria-label="enhanced table"
                >

                    <EnhancedTableHead classes={classes} phases={phases} />
                    <TableBody>
                        {filteredEntries ? filteredEntries.map((row) => <TableRowImpl row={row} phases={phases}
                                                                                      onShouldUpdate={()=> {
                                                                                          setShouldUpdate(shouldUpdate + 1);
                                                                                          props.onShouldUpdate();
                                                                                      }}/>)  : <></>}
                        <NewTableRowImpl
                            project={props.project}
                            phases={phases}
                            financialRate={props.financialRate}
                            onShouldUpdate={()=> {
                                    setShouldUpdate(shouldUpdate + 1);
                                    props.onShouldUpdate();
                                }}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
