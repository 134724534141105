import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import TimeWindowSelector from '../components/TimeWindowSelectorInkoop';
import InternExternTable from '../components/InternExternTable';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InkoopTable from "../components/InkoopTable";
import UitgifteTable from "../components/UitgifteTable";
import Title from "../components/Title";
import CircularProgress from "@material-ui/core/CircularProgress";
import {dateToMonthString} from "../helpers";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 619.5,
    },
}));

export default function UitgiftePage() {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dates, setDates] = useState();

    useEffect(() => {
        if(dates) {
            console.log("trigger")
            setData([]);
            setLoading(true);
            flaskAPI.get("/api/demconv/uitgifte", {
                params: {
                    'date_from': dates["fromDate"],
                    'date_till': dates["tillDate"],
                    'interval': '',
                }
            }).then(response => {
                if (response.status === 200) {
                    setData(response.data);
                } else {
                    // TODO error message
                }
                setLoading(false);
            })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                    setLoading(false);
                });
        }
    }, [dates, setAuthTokens]);



    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                    {/* Time Window */}
                        <Paper className={classes.paper}>
                            <Title>Datum van Uitgifte</Title>
                            <TimeWindowSelector onDateChanged={(datesVal) => {
                                setDates(datesVal);
                            }
                            }/>
                        </Paper>
            </Grid>
            {/* Table */}
            <Grid item xs={12}>
                <UitgifteTable loading={loading} data={data} label={dateToMonthString(dates?.fromDate)}/>
            </Grid>
        </Grid>
    );
}
