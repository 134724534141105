import React, {useState} from "react";
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import {NavButton} from '../components/listItems';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import MemoryIcon from '@material-ui/icons/Memory';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import BillingPage from './BillingPage';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import UsersTable from '../components/UsersTable';
import {useAuth} from "../context/auth";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EuroIcon from '@material-ui/icons/Euro';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import InkoopPage from "./InkoopPage";
import UitgiftePage from "./UitgiftePage";
import {MonitorPage} from "./MonitorPage";
import ProjectPage from "./ProjectPage";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    demconLogo: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        marginTop: '500px',
        marginBottom: '50px',
        height: '100%'
    },
    appBar: {
        backgroundColor: "#1C63AF",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function Dashboard() {
    const {roles} = useAuth();
    const [title, setTitle] = useState("Dashboard");
    const open = true;
    const {setAuthTokens} = useAuth();

    const classes = useStyles();

    const handlePageChange = (title) => {
        setTitle(title);
    };

    return (
        <Router>
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {title}
                        </Typography>
                        <Button onClick={() => setAuthTokens()} color="inherit" startIcon={<ExitToAppIcon/>}>
                            LOG OUT
                        </Button>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    <Divider/>
                    <div className={classes.toolbarIcon}>
                        {}
                    </div>
                    <List>
                        {(roles.includes("admin") || roles.includes("manager")) &&
                        <>
                            {/* Inkoop */}
                            <NavButton handleClick={handlePageChange} label="Inkoop" url="/inkoop"
                                       icon={<EuroIcon />} disabled={false} />

                            {/* Uitgifte */}
                            <NavButton handleClick={handlePageChange} label="Uitgifte" url="/uitgifte"
                                       icon={<ShoppingCartIcon/>} disabled={false}/>

                            <Divider/>

                            {/* Users */}
                            <NavButton handleClick={handlePageChange} label="Gebruikers" url="/gebruikers"
                                       icon={<SupervisorAccountIcon/>} disabled={false} />
                        </>}

                        {/* Billing */}
                        {(roles.includes("admin") || roles.includes("manager") || roles.includes("device")) &&
                        <NavButton handleClick={handlePageChange} label="Facturatie" url="/facturatie"
                                   icon={<MemoryIcon/>} disabled={true} />}

                        {(roles.includes("admin") || roles.includes("manager")) &&
                        <>
                            <Divider/>
                            <NavButton handleClick={handlePageChange} label="Monitor" url="/monitor"
                                       icon={<TrendingUpIcon/>} disabled={false} />
                        </>}
                    </List>
                    <Divider/>
                    <div className={classes.demconLogo}>
                        <img src="/demcon.png" width="90%" />
                    </div>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer}/>
                    <Container maxWidth="xl" className={classes.container}>
                        <div>
                            <Switch>
                                <Route path="/inkoop" render={() => <InkoopPage/>} />
                                <Route path="/uitgifte" render={() => <UitgiftePage/>} />
                                <Route path="/gebruikers" render={() => <UsersTable/>} />
                                <Route path="/facturatie" render={() => <BillingPage/>} />
                                <Route path="/monitor/:project" component={ProjectPage} />
                                <Route path="/monitor" render={() => <MonitorPage/>} />
                            </Switch>
                        </div>
                    </Container>
                </main>
            </div>
        </Router>
    );
}