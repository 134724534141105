import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import {statusToString} from "../helpers";
import Title from "./Title";
import CircularProgress from "@material-ui/core/CircularProgress";


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        right: theme.spacing(3),
        top: theme.spacing(3),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
    },

}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const OpdrachtenTable = withStyles(styles)((props) => {
    return (
            <Table style={{ minWidth: 750, maxWidth: 750 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Taakomschrijving</TableCell>
                        <TableCell>VrijVeld</TableCell>
                        <TableCell align="right">Totaal</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props?.data.map((row) => (
                        <TableRow
                            key={row?.rows?.TaakOmschrijving}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>{row?.rows?.Type}</TableCell>
                            <TableCell>{row?.rows?.TaakOmschrijving}</TableCell>
                            <TableCell>{row?.rows?.VrijVeld}</TableCell>
                            <TableCell align="right">{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(row?.rows?.Totaal)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
    );
});

const FacturenTable = withStyles(styles)((props) => {
    return (
        <Table style={{ minWidth: 750, maxWidth: 750 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Factuurdatum</TableCell>
                    <TableCell>Factuurnummer</TableCell>
                    <TableCell>Omschrijving</TableCell>
                    <TableCell align="right">Bedrag</TableCell>
                    <TableCell align="right">Saldo resterend</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props?.data.map((row) => (
                    <TableRow
                        key={row?.rows?.Item_omschrijving}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell>{row?.rows?.Factuurdatum}</TableCell>
                        <TableCell>{row?.rows?.Factuurnummer}</TableCell>
                        <TableCell>{row?.rows?.Item_omschrijving}</TableCell>
                        <TableCell align="right">{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(row?.rows?.Bedrag)}</TableCell>
                        <TableCell align="right">{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(row?.rows?.Saldo)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
});

export default function OpdrachtDialog(props) {

    return (
        <Dialog onClose={props.onClose} aria-labelledby="customized-dialog-title" open={props.open} fullWidth maxWidth={false}>
            <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
                Opdrachten - {props.project}
                {props.loading ? ( <CircularProgress color="secondary" size={30}/>) : (<></>)}
            </DialogTitle>
            <DialogContent dividers>
                <OpdrachtenTable data={props.opdrachtenData} />
                <FacturenTable data={props.facturenData} />
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>
    );
}