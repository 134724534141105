import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import {statusToString} from "../helpers";
import Title from "./Title";
import CircularProgress from "@material-ui/core/CircularProgress";


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        right: theme.spacing(3),
        top: theme.spacing(3),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const BasicTable = withStyles(styles)((props) => {
    return (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Omschrijving</TableCell>
                        <TableCell>Aanmaak</TableCell>
                        <TableCell>Order</TableCell>
                        <TableCell>Levering</TableCell>
                        <TableCell align="right">Aantal</TableCell>
                        <TableCell align="right">Prijs</TableCell>
                        <TableCell align="right">Totaal</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props?.data.map((row) => (
                        <TableRow
                            key={row.ikwr_num}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.ikwr_num}
                            </TableCell>
                            <TableCell>{row.ikwr_oms_1 + ' ' + row.ikwr_oms_2 + ' ' + row.ikwr_oms_2}</TableCell>
                            <TableCell>{moment(row.ikwr_dat_aanmaak).format('LL')}</TableCell>
                            <TableCell>{moment(row.ikwr_dat_order).format('LL')}</TableCell>
                            <TableCell>{moment(row.ikwr_dat_lev).format('LL')}</TableCell>
                            <TableCell align="right">{row.ikwr_aantal}</TableCell>
                            <TableCell align="right">{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(row.ikwr_prijs_netto_lv)}</TableCell>
                            <TableCell align="right">{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(row.bedrag)}</TableCell>
                            <TableCell>{statusToString(row.iost_status)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
    );
});

export default function InkoopDialog(props) {

    return (
        <Dialog onClose={props.onClose} aria-labelledby="customized-dialog-title" open={props.open} fullWidth maxWidth={false}>
            <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
                Inkooporder - {props.ikwh}
                {props.loading ? ( <CircularProgress color="secondary" size={30}/>) : (<></>)}
            </DialogTitle>
            <DialogContent dividers>
                <BasicTable data={props.data} />
            </DialogContent>
            <DialogActions>
                {/*<Button autoFocus onClick={props.onClose} color="primary">*/}
                {/*    Export*/}
                {/*</Button>*/}
            </DialogActions>
        </Dialog>
    );
}