import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

function Title(props) {
    return (
        <Typography component="h2" variant="h6" color="primary" gutterBottom style={{fontSize: "17px"}}>
            {props.children}
        </Typography>
    );
}

function StyledCell(props) {
    return (
        <TableCell
            align={props.align ? props.align : "left"}
            colSpan={props.colSpan ? props.colSpan : 1}
            style={{
                padding: "1px 1px",
                fontSize: "14px",
            }}>{props.children}</TableCell>
    );
}


export default function BasicProjectCard(props) {
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <TableContainer>
                <Title style={{fontSize: "10px"}}>
                    {props.title}
                    {props.loading ? (<CircularProgress color="secondary" size={22}/>) : (<></>)}
                </Title>
                <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                        {props.rows.map((row) => (
                            <TableRow>
                                <StyledCell>{row.caption}</StyledCell>
                                {row.isInput ?
                                    <TextField id="standard-basic"
                                               style={{width: row.width ? row.width : '10ch'}}
                                               value={row.value} name={row.key} onChange={props.onHandleChange} />

                                    : row.isButton ?
                                        <StyledCell><Button size="small" color="primary" onClick={props.onClick}>{props.buttonLabel}</Button></StyledCell>
                                        :
                                    <>
                                        <StyledCell align={row.numeric ? "right" : "left"}>{row.value}</StyledCell>
                                        {row.secondValue ?
                                            <StyledCell align="right">{row.secondValue}</StyledCell>
                                            :
                                            <></>
                                        }
                                    </>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}