

import React, {forwardRef, useEffect, useRef, useState} from 'react';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';
import Search from '@material-ui/icons/Search';
import PrintIcon from '@material-ui/icons/Print';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {Grid, Paper, TableCell, TableHead, TableRow} from "@material-ui/core";
import {useAuth} from "../context/auth";
import {flaskAPI} from "../flaskAPI";
import BasicProjectCard from "../components/BasicProjectCard";
import {formatDate, formatEuro, formatFloat} from "../helpers";
import ProjectEntryTable from "../components/ProjectEntryTable"
import {makeStyles} from "@material-ui/core/styles";
import ProjectChart from "../components/ProjectChart";
import {useReactToPrint} from "react-to-print";
import Button from "@material-ui/core/Button";
import InkoopDialog from "../components/InkoopDialog";
import OpdrachtDialog from "../components/OpdrachtDialog";

const tableIcons = {
    Add: forwardRef((props, ref) => <PlaylistAddIcon {...props} ref={ref}/>),
    AddLibrary: forwardRef((props, ref) => <LibraryAddIcon {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveIcon {...props} ref={ref}/>),
    FileCopy: forwardRef((props, ref) => <FileCopyIcon {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};


function StyledHeaderCell(props) {
    return (
        <TableCell
            align='center'
            colSpan={props.colSpan ? props.colSpan : 1}
            style={{
                padding: "0px 0px",
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                borderLeft: "1px solid black",
                backgroundColor: "#5B9BD5",
                fontSize: "13px",
                color: "#FFF"
            }}><b>{props.children}</b></TableCell>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    paperLessPadding: {
        padding: theme.spacing(1),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 619.5,
    },
    hidePrint: {
        '@media print': {
            display: 'none'
        }
    }
}));


export default function ProjectPage({match}) {
    const classes = useStyles();
    const {
        params: {project},
    } = match;

    const { useState } = React;
    const [shouldUpdateHistoric, setShouldUpdateHistoric] = useState(0);
    const {setAuthTokens} = useAuth();
    const [loadingMeta, setLoadingMeta] = useState(false);
    const [loadingHoursSummary, setLoadingHoursSummary] = useState(false);
    const [isProjectDeleted, setIsProjectDeleted] = useState(false)

    const [projectConfig, setProjectConfig] = useState({
        'financialVF': '',
        'financialVK': '',
        'financialRate': 95,
        'statusAangemaakt': '',
        'statusStage': '',
        'precalcMaterials': '',
    })


    const [projectMetaData, setProjectMetaData] = useState({})
    const [projectHoursSummary, setProjectHoursSummary] = useState([])
    const [projectMonthHoursSummary, setProjectMonthHoursSummary] = useState([])

    function formatProjectMetaStr(val) {
        return projectMetaData ? projectMetaData[val] ? projectMetaData[val] : "" : "";
    }

    const projectInfoRows = [
        {caption: "Project", value: formatProjectMetaStr('Project')},
        {caption: "Omschrijving", value: formatProjectMetaStr('Omschrijving')},
        {caption: "Customer", value: formatProjectMetaStr('VerkooprelatieNaam')},
    ];

    const financialRows = [
        {caption: "Total PO's", value: formatEuro(projectMetaData['Totaal'])},
        {caption: "", isButton: true},
        {caption: "VF", key: 'financialVF', value: projectConfig.financialVF, isInput: true},
        {caption: "VK", key: 'financialVK', value: projectConfig.financialVK, isInput: true},
        {caption: "Rate", key: 'financialRate', value: projectConfig.financialRate, isInput: true},
    ];

    const statusRows = [
        {caption: "Aangemaakt", value: formatDate(projectMetaData['Aangemaakt_op'])},
        {caption: "Stage", value: formatProjectMetaStr('ProjectFaseOmschrijving')},
        {caption: "Historisch", isButton: true},
    ];

    const coordinationRows = [
        {caption: "Project Leider", value: formatProjectMetaStr('Interne_projectleider_Naam')},
        ...projectHoursSummary.map((row) => ({caption: row['Itemcode'], value: row['Naam'], secondValue: row['Aantal'] + ' hr'}))
    ];
    const coordMonthRows = [
        ...projectMonthHoursSummary.map((row) => ({caption: row['Itemcode'], value: row['Naam'], secondValue: row['Aantal'] + ' hr'}))
    ];
    const [precalcRows, setPrecalcRows] = useState([])

    // Project Config
    const updateConfig = (newData) => {
        setTimeout(() => {
            flaskAPI.put('/api/demconv/projecten/config/' + project, {"json_data": newData}).then(response => {
            }).catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            })
        }, 400)
    }

    useEffect(() => {
        flaskAPI.get('/api/demconv/projecten/config/' + project).then(response => {
            if("json_data" in response.data) {
                let newState = response.data["json_data"];
                setProjectConfig({...projectConfig, ...newState});
            }
        })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    const handleChange = (event) => {
        let newState = {...projectConfig, [event.target.name]: event.target.value}
        setProjectConfig(newState);
        updateConfig(newState)
    };


    // Project Meta
    useEffect(() => {
        setLoadingMeta(true);
        flaskAPI.get('/api/demconv/projecten/meta/' + project)
            .then(response => {
                setProjectMetaData(response.data);
                setLoadingMeta(false);
            })
            .catch(error => {
                setLoadingMeta(false);
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    // Project Opdracht regels
    useEffect(() => {
        setLoadingOpdrachten(true);
        setOpdrachtenData([]);
        flaskAPI.get('/api/demconv/opdrachten/' + project)
            .then(response => {
                setOpdrachtenData(response.data);
                setLoadingOpdrachten(false);
            })
            .catch(error => {
                setLoadingOpdrachten(false);
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    // Project Factuur regels
    useEffect(() => {
        setFacturenData([]);
        flaskAPI.get('/api/demconv/facturen/' + project)
            .then(response => {
                setFacturenData(response.data);
                // setLoadingOpdrachten(false);
            })
            .catch(error => {
                // setLoadingOpdrachten(false);
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    // Project Hours Summary (what persons worked on it)
    useEffect(() => {
        setLoadingHoursSummary(true);
        flaskAPI.get('/api/demconv/projecten/hours/summary/' + project)
            .then(response => {
                setLoadingHoursSummary(false);
                setProjectHoursSummary(response?.data?.all);
                setProjectMonthHoursSummary(response?.data?.this_month);
            })
            .catch(error => {
                setLoadingHoursSummary(false);
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens]);

    // Project Hours Summary (what persons worked on it)
    useEffect(() => {
        setIsProjectDeleted(false);
        flaskAPI.get('/api/demconv/projecten/historic/' + project)
            .then(response => {
                setIsProjectDeleted(response?.data);
            })
            .catch(error => {
                setLoadingHoursSummary(false);
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens, shouldUpdateHistoric]);

    const handleAdd = (newData) => {
        return new Promise((resolve, reject) => {
            if (newData) {
                newData.project = project;
                flaskAPI.post('/api/demconv/projecten/entries', newData).then(response => {
                    if (response.status === 201) {
                        resolve();
                    } else {
                        reject();
                    }
                }).catch(error => {
                    reject();
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                })
            } else {
                reject();
            }
        });
    }

    const makeHistorisch = () => {
        return new Promise((resolve, reject) => {
            flaskAPI.post('/api/demconv/projecten/historic/' + project).then(response => {
                setShouldUpdateHistoric(shouldUpdateHistoric+1)
                if (response.status === 200) {
                    resolve();
                } else {
                    reject();
                }
            }).catch(error => {
                reject();
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            })
        });
    }

    const unMakeHistorisch = () => {
        return new Promise((resolve, reject) => {
            flaskAPI.delete('/api/demconv/projecten/historic/' + project).then(response => {
                setShouldUpdateHistoric(shouldUpdateHistoric+1)
                if (response.status === 200) {
                    resolve();
                } else {
                    reject();
                }
            }).catch(error => {
                reject();
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            })
        });
    }

    const [plotData, setPlotData] = useState([]);
    const [shouldUpdatePlot, setShouldUpdatePlot] = useState(0);
    useEffect(() => {
        setPlotData([])
        flaskAPI.get('/api/demconv/projecten/entries/' + project, {params: {"is_hidden": false}})
            .then(response => {
                setPlotData(
                    response.data.map((row,index) => {
                        const act_hrs_total = row?.act_hrs_json.reduce((partialSum, a) => partialSum + formatFloat(a?.Aantal), 0);
                        const etc_hrs_total = row?.etc_hrs_json.reduce((partialSum, a) => partialSum + formatFloat(a?.Aantal), 0);
                        const forecast_total_hrs = act_hrs_total + etc_hrs_total;
                            return {
                            last_change: row?.last_change,
                            act_hrs_total: act_hrs_total,
                            etc_hrs_total: etc_hrs_total,
                            forecast_total_hrs: forecast_total_hrs
                        }}
                    )
                );
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens, shouldUpdatePlot]);

    const downloadComponentRef = useRef();
    const handleDownloadButtonClick = useReactToPrint({
        content: () => downloadComponentRef.current.cloneNode(true),
        documentTitle: project,
    })

    const [opdrachtOpen, setOpdrachtOpen] = useState(false);

    const handleClickOpen = () => {
        setOpdrachtOpen(true);
    };
    const handleClose = () => {
        setOpdrachtOpen(false);
    };

    const [opdrachtenData, setOpdrachtenData] = useState([]);
    const [facturenData, setFacturenData] = useState([]);
    const [loadingOpdrachten, setLoadingOpdrachten] = useState(false);

    return (
        <>
        <OpdrachtDialog onClose={handleClose}
                        opdrachtenData={opdrachtenData}
                        facturenData={facturenData}
                        loading={loadingOpdrachten} project={project} aria-labelledby="customized-dialog-title" open={opdrachtOpen} />
    <Grid container spacing={3}  ref={downloadComponentRef}>
            <Button
                variant='contained'
                startIcon={<PrintIcon/>}
                disableElevation
                className={classes.hidePrint}
                onClick={handleDownloadButtonClick}
            >
                Print
            </Button>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <BasicProjectCard title="Project info" rows={projectInfoRows} loading={loadingMeta}/>
                    </Grid>
                    <Grid item xs={3} className={classes.hidePrint}>
                        <
                            BasicProjectCard
                            title="Financial"
                            rows={financialRows}
                            onHandleChange={handleChange}
                            onClick={handleClickOpen}
                            buttonLabel={"More"}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.hidePrint}>
                        <BasicProjectCard
                            title="Status"
                            rows={statusRows}
                            onClick={isProjectDeleted ? unMakeHistorisch : makeHistorisch}
                            buttonLabel={isProjectDeleted ? "Make non-historic" : "Make historic" }
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <BasicProjectCard title="Coord. (All)" rows={coordinationRows} loading={loadingHoursSummary} />
                    </Grid>
                    <Grid item xs={3}>
                        <BasicProjectCard title="Coord. (This month)" rows={coordMonthRows} loading={loadingHoursSummary} />
                    </Grid>

                    {/* Pre-calc rows */}
                    {/*
                    <Grid item xs={3}>
                        <BasicProjectCard title="Precalc" rows={precalcRows} onHandleChange={handleChange} />
                    </Grid>
                    */
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.hidePrint}>
                <Paper className={classes.paperLessPadding}>
                    <ProjectEntryTable
                        project={project}
                        onShouldUpdate={() => setShouldUpdatePlot(shouldUpdatePlot+1)}
                        financialRate={projectConfig.financialRate ? projectConfig.financialRate : 0.0}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <ProjectChart
                    project={project}
                    plotData={plotData}
                />
            </Grid>
        </Grid>
        </>
    )
}
