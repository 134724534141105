import React, {forwardRef, useState} from 'react';
import MaterialTable from 'material-table';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {withStyles} from '@material-ui/core/styles';
import Remove from "@material-ui/icons/Remove";

const tableIcons = {
    Add: forwardRef((props, ref) => <PlaylistAddIcon {...props} ref={ref}/>),
    AddLibrary: forwardRef((props, ref) => <LibraryAddIcon {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    FileCopy: forwardRef((props, ref) => <FileCopyIcon {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
});

function statusToString(status) {
    switch (status) {
        case 1:
            return "AANGEMAAKT"
        case 2:
            return "GEFIATTEERD"
        case 3:
            return "BESTELD"
        case 4:
            return "BINNENGEKOMEN"
        case 5:
            return "GEKEURD"
        case 6:
            return "FACTUUR GEBOEKT"
        case 7:
            return "HISTORISCH"
        case 8:
            return "VERWIJDERD"
        case 9:
            return "NIET ONTVANGEN"
        case 10:
            return "PAKBON VERWERKT"
        case 11:
            return "NIET GEFACTUREERD"
        case 12:
            return "HISTORISCH"
    }
}


function UitgifteTable(props) {
    const {classes} = props;
    const [initialFormData, setInitialFormData] = useState();
    const tableRef = React.createRef();

    const columns = [
        {
            title: 'PO nummer',
            field: 'torh_num',
        },
        {
            title: 'Omschrijving',
            field: 'ughs_order_oms',
        },
        {
            title: 'Part nummer',
            field: 'ughs_part',
        },
        {
            title: 'Reg artikel',
        },
        {
            title: 'Datum',
            field: 'ughs_dat_uitgifte',
            type: 'date',
            defaultSort: 'desc'
        },
        {
            title: 'Aantal',
            field: 'ughs_aantal',
        },
        {
            title: 'Stukprijs',
            field: 'ughs_kostprijs',
            render: rowData => new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(rowData.ughs_kostprijs),
        },
        {
            title: 'Totale kostprijs(ex BTW)',
            field: 'ughs_tot_kostprijs',
            render: rowData => new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(rowData.ughs_tot_kostprijs),
        },
    ];
    return (
        <>
            <MaterialTable
                options={{
                    exportAllData: true,
                    exportButton: {
                        csv: true,
                        pdf: false
                    },
                    cellStyle: {
                        height: 75,
                    },
                    pageSize: 20,
                    pageSizeOptions: [20],
                }}
                icons={tableIcons}
                isLoading={props.loading}
                title={"Uitgifte" + (props.label ? " - " + props.label : "")}
                columns={columns}
                data={props.data}
                initialFormData={initialFormData}
                tableRef={tableRef}

            />
        </>
    );
}

export default withStyles(styles)(UitgifteTable);
