import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import Title from './Title';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import CircularProgress from '@material-ui/core/CircularProgress';
import LedgerPlotter from './LedgerPlotter';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import {useCurrentPng} from "recharts-to-png";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";

const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    notation: "compact",
    compactDisplay: "short"
})

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

const plotColors = ["#cc5f43", "#8176cc", "#ac973e", "#c75a93", "#5ba966"]

export default function ProjectChart(props) {
    var FileSaver = require('file-saver');
    const classes = useStyles();
    const theme = useTheme();
    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [entries, setEntries] = useState([]);

    const [plotOptions, setPlotOptions] = useState();

    const [getPng, { ref, isLoading }] = useCurrentPng();
    const handleDownload = useCallback(async () => {
        const png = await getPng();

        // Verify that png is not undefined
        if (png) {
            // Download with FileSaver
            FileSaver.saveAs(png, props.project + '.png');
        }
    }, [getPng]);


    function formatXAxis(tickItem) {
        return moment(tickItem).format('DD MMM YYYY')
        // return tickItem
    }

    function formatTooltipDate(tickItem) {
        return moment(tickItem).format('DD MMMM YYYY')
    }

    return (
        <Paper className={classes.paper}>
            <div>
                <IconButton color="primary" onClick={handleDownload} aria-label="saveChart">
                    <SaveIcon />
                </IconButton>
            </div>
            <div>
            <ResponsiveContainer width="100%" height={400}>
            {/*<LineChart width={1200} height={400} data={plotData}*/}
            <LineChart data={props.plotData} ref={ref}
                       margin={{
                           top: 0,
                           right: 0,
                           bottom: 0,
                           left: 0,
                       }}
            >

                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="last_change"
                       tickFormatter={tick => {
                           return formatXAxis(tick);
                       }}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" name="Actual hrs" dataKey="act_hrs_total" stroke={plotColors[0]} dot={false} strokeWidth={2}/>
                <Line type="monotone" name="EtC hrs" dataKey="etc_hrs_total" stroke={plotColors[1]} dot={false} strokeWidth={2}/>
                <Line type="monotone" name="Forecast hrs" dataKey="forecast_total_hrs" stroke={plotColors[2]} dot={false} strokeWidth={2}/>
                {/*{plotOptions ? plotOptions["ledger"].map(*/}
                {/*    (ledger, index) =>*/}
                {/*        <Line type="monotone" name={ledger} dataKey={ledger.split(' ')[0]}*/}
                {/*              stroke={plotColors[index % 5]} dot={false}/>*/}
                {/*) : <></>}*/}
                {/*{plotOptions ? plotOptions["sum"] ? plotOptions["sum"].length > 0 ?*/}
                {/*    <Line type="monotone" name={"Sum of " + plotOptions["sum"].join(", ")}*/}
                {/*          dataKey="netto_amount" stroke={plotColors[4]} dot={false}/>*/}
                {/*    : <></> : <></> : <></>}*/}
            </LineChart>
            </ResponsiveContainer>
            </div>

        </Paper>
    );
}
