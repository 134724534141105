import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import TimeWindowSelector from '../components/TimeWindowSelectorBilling';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import BillingTable from "../components/BillingTable";
import BillingChart from "../components/BillingChart";
import clsx from "clsx";
import {formatDate} from '../helpers';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 461,
    },
}));

export default function BillingPage() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight2);
    const {setAuthTokens} = useAuth();
    const [loadingTable, setLoadingTable] = useState(false);
    const [loadingChart, setLoadingChart] = useState(false);
    const [events, setEvents] = useState([]);

    const [chartData, setChartData] = useState([]);
    const [options, setOptions] = useState();

    useEffect(() => {
        if (options && options.fromDate && options.interval) {
            setEvents([]);
            setLoadingTable(true);
            flaskAPI.get('/api/billing/events', {
                params: {
                    'date_from': formatDate(options["fromDate"]),
                    'date_till': formatDate(options["tillDate"]),
                    'interval': options["interval"],
                }
            }).then(response => {
                if (response.status === 200) {
                    setEvents(response.data);
                } else {
                    // TODO error message
                }
                setLoadingTable(false);
            }).catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
                setLoadingTable(false);
            });
        }
    }, [options, setAuthTokens]);


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container direction="column" spacing={3} justify="center">
                    {/* Time Window */}
                    <Grid item>
                        <Paper className={classes.paper}>
                            <TimeWindowSelector onSubmit={(options) => {
                                setOptions(options);
                            }
                            }/>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            {/* Table */}
            <Grid item xs={12}>
                <BillingTable loading={loadingTable} dataRows={events} />
            </Grid>
        </Grid>
    );
}
