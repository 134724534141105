import React, {forwardRef, useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {withStyles} from '@material-ui/core/styles';
import Remove from "@material-ui/icons/Remove";
import TocIcon from '@material-ui/icons/Toc';
import InkoopDialog from "./InkoopDialog";
import {flaskAPI} from "../flaskAPI";
import {useAuth} from "../context/auth";
import {statusToString} from "../helpers";

const tableIcons = {
    Add: forwardRef((props, ref) => <PlaylistAddIcon {...props} ref={ref}/>),
    AddLibrary: forwardRef((props, ref) => <LibraryAddIcon {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    FileCopy: forwardRef((props, ref) => <FileCopyIcon {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>),
    Toc: forwardRef((props, ref) => <TocIcon {...props} ref={ref}/>)
};

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
});

function InkoopTable(props) {
    const {classes} = props;
    const [initialFormData, setInitialFormData] = useState();
    const tableRef = React.createRef();

    const [open, setOpen] = useState(false);
    const [selectedIkwh, setSelectedIkwh] = useState();
    const [selectedTorh, setSelectedTorh] = useState();

    const handleClickOpen = (rowData) => {
        setSelectedIkwh(rowData.ikwh_num);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const {setAuthTokens} = useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(selectedIkwh) {
            setData([]);
            setLoading(true);
            flaskAPI.get("/api/demconv/inkoop/" + selectedIkwh).then(response => {
                if (response.status === 200) {
                    setData(response.data);
                } else {
                    // TODO error message
                }
                setLoading(false);
            })
                .catch(error => {
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                    setLoading(false);
                });
        }
    }, [selectedIkwh, setAuthTokens]);

    const columns = [
        {
            title: 'Inkoop order',
            field: 'ikwh_num',
        },
        {
            title: 'Verkoop order',
            field: 'vorh_num',
        },
        {
            title: 'Crediteur',
            field: 'pcrd.pcrd_bnaam',
            cellStyle: {
                minWidth: 200,
                maxWidth: 200
            }
        },
        {
            title: 'Omschrijving',
            field: 'ikwh_oms_1',
        },
        {
            title: 'Datum (laatste) levering',
            field: 'ikwr_dat_lev',
        },
        {
            title: 'Bedrag',
            field: 'bedrag',
            render: rowData => new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(rowData.bedrag),
        },
        {
            title: 'Status',
            field: 'iost_status',
            render: rowData => statusToString(rowData.iost_status),
        },
    ];


    return (
        <>
            <InkoopDialog onClose={handleClose} data={data} loading={loading} ikwh={selectedIkwh} aria-labelledby="customized-dialog-title" open={open} />
            <MaterialTable
                options={{
                    exportAllData: true,
                    exportButton: {
                        csv: true,
                        pdf: false
                    },
                    cellStyle: {
                        height: 75,
                    },
                    pageSize: 20,
                    pageSizeOptions: [20],
                }}
                icons={tableIcons}
                isLoading={props.loading}
                title={"Inkoop" + (props.label ? " - Levering " + props.label : "")}
                columns={columns}
                data={props.data}
                initialFormData={initialFormData}
                tableRef={tableRef}
                localization={{
                    header : {
                        actions: ''
                    }
                }}
                actions={[
                    {
                        icon: () => <TocIcon />,
                        tooltip: 'View all',
                        onClick: (event, rowData) => handleClickOpen(rowData)
                    }
                ]}

            />
        </>
    );
}

export default withStyles(styles)(InkoopTable);
