import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {tableIcons} from "../styles";
import Button from "@material-ui/core/Button";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
});

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    }
});

function BillingTable(props) {
    const {classes} = props;

    const columns = [
        {
            title: 'Client',
            field: 'client',
        },
        {
            title: 'Date',
            field: 'event_timestamp',
            render: rowData => new Date(rowData["event_timestamp"] * 1000).toLocaleString(),
        },
        {
            title: 'Type',
            field: 'event_type',
        },
        {
            title: 'Description',
            field: 'event_desc',
        },
        {
            title: 'IP',
            field: 'ip',
        },
        {
            title: 'User id',
            field: 'user_id',
        },
    ];
    return (
        <>
            <MaterialTable
                icons={tableIcons}
                isLoading={props.loading}
                title="Facturatie Verholt"
                columns={columns}
                data={props.dataRows ? props.dataRows : []}
                options={{
                    exportAllData: true,
                    paging: true,
                    search: true,
                    sorting: true,
                    exportButton: {
                        csv: true,
                        pdf: true
                    },
                    pageSize: 5,
                    pageSizeOptions: [5],
                }}
            />
        </>
    );
}

export default withStyles(styles)(BillingTable);
