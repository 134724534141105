import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import {formatDate} from '../helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    formControl: {
        margin: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(1),
    },
    timeButtons: {
        marginLeft: theme.spacing(5),
    }
}));

function TimeWindowButtons(props) {
    function renderButton(date) {
        const thisDate = new Date(date.setMonth(date.getMonth() - 1));
        const caption = thisDate.toLocaleString('default', {month: 'long'});
        return (
            <Grid item>
                <Button variant="outlined" onClick={() => props.onClick(thisDate)}>{caption}</Button>
            </Grid>
        );
    }

    const date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() + 1);
    return (
        <Grid container spacing={2}>
            {renderButton(date)}
            {renderButton(date)}
            {renderButton(date)}
            {renderButton(date)}
        </Grid>
    );
}

export default function TimeWindowSelector(props) {
    const dateInitial = new Date();
    dateInitial.setDate(1);
    dateInitial.setMonth(dateInitial.getMonth() - 1);

    const [month, setMonth] = useState(dateInitial);
    const [error, setError] = useState(false);

    const classes = useStyles();

    const handleMonthChange = (date) => {
        if (isValidDate(date)) {
            setError(false);
            setMonth(date);
        } else {
            setError(true);
        }
    };

    function isValidDate(date) {
        return date instanceof Date && !isNaN(date);
    }

    useEffect(() => {
        if (isValidDate(month)) {
            let fromDate = new Date(month);
            fromDate.setDate(1);
            let tillDate = new Date(month);
            tillDate.setMonth(month.getMonth() + 1);
            tillDate.setDate(0);
            props.onDateChanged({fromDate: formatDate(fromDate), tillDate: formatDate(tillDate)});
        }
    }, [month]);

    return (
        <div className={classes.root}>
                <FormControl component="fieldset" error={error} className={classes.formControl}>
                    <FormGroup>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                views={["year", "month"]}
                                error={error}
                                disableToolbar
                                variant="inline"
                                format="MM/yyyy"
                                margin="normal"
                                id="fromDate"
                                label={props.label}
                                value={month}
                                onChange={handleMonthChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormGroup>
                </FormControl>
            <div className={classes.timeButtons}>
                <TimeWindowButtons onClick={(date) => setMonth(date)}/>
            </div>
        </div>
    );
}

