import React, {forwardRef, useEffect, useState} from 'react';
import MaterialTable from "material-table";
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {Grid, TableCell, TableHead, TableRow} from "@material-ui/core";
import {useAuth} from "../context/auth";
import {flaskAPI} from "../flaskAPI";
import {formatDate, formatFloat} from "../helpers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core/styles";
import {COLORS} from "../styles";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import {deepOrange, deepPurple} from "@mui/material/colors";

const tableIcons = {
    Add: forwardRef((props, ref) => <PlaylistAddIcon {...props} ref={ref}/>),
    AddLibrary: forwardRef((props, ref) => <LibraryAddIcon {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    FileCopy: forwardRef((props, ref) => <FileCopyIcon {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

function StyledHeaderCell(props) {
    return (
        <TableCell
            align='center'
            colSpan={props.colSpan ? props.colSpan : 1}
            style={{
                padding: "0px 0px",
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                borderLeft: "1px solid black",
                backgroundColor: "#5B9BD5",
                fontSize: "13px",
                color: "#FFF"
            }}><b>{props.children}</b></TableCell>
    );
}

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    },
    gray: {
        fontSize: 15,
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
        fontSize: 15,
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
        fontSize: 15,
    },
    iconButton: {
        padding: 0,
    }
});

function MonitorOverviewTable(props) {
    const { useState } = React;
    const {classes} = props;
    const [showHistoric, setShowHistoric] = useState(false)
    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const filteredData = showHistoric ? data : data.filter(d => d["is_deleted"] == false)
    const queryParameters = new URLSearchParams(window.location.search)
    const pmFilter = queryParameters.get("pm")

    const setPmFilter = (newVal) => {
        if(pmFilter==newVal) {
            props.handleClick('/monitor');
        } else {
            props.handleClick('/monitor?pm='+newVal);
        }
    }

    const columns = [
        { title: 'Project', field: 'project', editable: 'never',
            width: 80
        },
        { title: 'Description', field: 'description', editable: 'never' },
        { title: 'Customer', field: 'customer', editable: 'never' },
        {
            title: 'Total PO\'s', field: 'totalPoValue', editable: 'never', type: 'currency', currencySetting:{ locale: 'nl-NL',currencyCode:'EUR', minimumFractionDigits:0, maximumFractionDigits:0},
            cellStyle: {
                backgroundColor: COLORS.green,
                padding: "2px 2px",
                fontSize: "13px",
                borderBottom: "0",
            },
        },
        { title: 'Updated', field: 'last_change', editable: 'never', type: 'date',
            render: rowData => rowData.last_change ? formatDate(rowData.last_change) : ''
        },
        { title: 'Act Total', field: 'act_hrs_total', type: 'numeric', render: rowData => formatFloat(rowData.act_hrs_total),
            cellStyle: {
                backgroundColor: COLORS.green,
                padding: "2px 2px",
                fontSize: "13px",
                borderBottom: "0",
            },
        },
        { title: 'Act AFAS Materials', field:'act_mtrl_afas', type: 'currency', currencySetting:{ locale: 'nl-NL',currencyCode:'EUR', minimumFractionDigits:0, maximumFractionDigits:0},
            cellStyle: {
                backgroundColor: COLORS.lightBlue,
                padding: "2px 2px",
                fontSize: "13px",
                borderBottom: "0",
            },
         },
        { title: 'Act Fledge Materials', field:'act_mtrl_fledge', type: 'currency', currencySetting:{ locale: 'nl-NL',currencyCode:'EUR', minimumFractionDigits:0, maximumFractionDigits:0},
            cellStyle: {
                backgroundColor: COLORS.lightBlue,
                padding: "2px 2px",
                fontSize: "13px",
                borderBottom: "0",
            },
        },
        { title: 'EtC Total', field: 'etc_hrs_total', type: 'numeric', render: rowData => rowData.etc_hrs_total,
            cellStyle: {
                backgroundColor: COLORS.green,
                padding: "2px 2px",
                fontSize: "13px",
                borderBottom: "0",
            },
        },
        { title: 'EtC Materials', field:'etc_mtrl', type: 'currency', currencySetting:{ locale: 'nl-NL',currencyCode:'EUR', minimumFractionDigits:0, maximumFractionDigits:0},
            cellStyle: {
                backgroundColor: COLORS.lightBlue,
                padding: "2px 2px",
                fontSize: "13px",
                borderBottom: "0",
            },
        },
        { title: 'Forecast total cost', field: 'forecast_total_cost', editable: 'never', type: 'currency', currencySetting:{ locale: 'nl-NL',currencyCode:'EUR', minimumFractionDigits:0, maximumFractionDigits:0},
            cellStyle: {
                padding: "2px 2px",
                fontSize: "13px",
                fontWeight: 500,
            },
        },
        { title: 'Comment', field:'comment', initialEditValue: ' ', type: 'string',  render: rowData => rowData.comment ? rowData.comment!='0' ? rowData.comment : '' : '' },
    ];

    useEffect(() => {
        setLoading(true);
        setData([]);
        flaskAPI.get('/api/demconv/projecten', {params: {show_historical: showHistoric, pm: pmFilter}})
            .then(response => {
                setLoading(false);
                setData(
                response.data.map((row,index) => {
                    const act_hrs_total = row?.act_hrs_json.reduce((partialSum, a) => partialSum + formatFloat(a?.Aantal), 0);
                    const act_hrs_cost = row?.act_hrs_json.reduce((partialSum, a) => partialSum + formatFloat(a?.AantalEur), 0);

                    const etc_hrs_total = row?.etc_hrs_json.reduce((partialSum, a) => partialSum + formatFloat(a?.Aantal), 0);
                    const etc_hrs_cost = row?.etc_hrs_json.reduce((partialSum, a) => partialSum + formatFloat(a?.AantalEur), 0);
                    const forecast_total_cost = formatFloat(row?.act_mtrl_afas) + formatFloat(row?.etc_mtrl) + act_hrs_cost + etc_hrs_cost;

                    return {
                        project: row?.Project,
                        description: row?.Omschrijving,
                        customer: row?.VerkooprelatieNaam,
                        totalPoValue: row?.Totaal,
                        last_change: row?.last_change,
                        act_hrs_total: act_hrs_total,
                        act_mtrl_afas: row?.act_mtrl_afas,
                        act_mtrl_fledge: row?.act_mtrl_fledge,
                        etc_hrs_total: etc_hrs_total,
                        etc_mtrl: row?.etc_mtrl,
                        forecast_total_cost: forecast_total_cost,
                        comment: row?.comment,
                        is_deleted: row?.is_deleted
                    }}
                ));
            })
            .catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }, [setAuthTokens, pmFilter]);


    return (
        <MaterialTable
            title={
                <div className={classes.tableTitle}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography noWrap className={classes.tableTitleText} variant="h5">
                            Project Monitor
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => setPmFilter("EDG")}
                            size="large">
                            <Avatar className={pmFilter=="EDG" ? classes.orange : classes.gray}>EDG</Avatar>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => setPmFilter("NBC")}
                            size="large">
                            <Avatar className={pmFilter=="NBC" ? classes.orange : classes.gray}>NBC</Avatar>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => setPmFilter("WHC")}
                            size="large">
                            <Avatar className={pmFilter=="WHC" ? classes.orange : classes.gray}>WHC</Avatar>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => setPmFilter("EQU")}
                            size="large">
                            <Avatar className={pmFilter=="EQU" ? classes.orange : classes.gray}>EQU</Avatar>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={<Switch checked={showHistoric} onChange={(event) => setShowHistoric(event.target.checked)}
                                             name="showHistoricSwitch"/>}
                            label="Show historic"
                        />
                    </Grid>
                </Grid>
                </div>
            }
            icons={tableIcons}
            columns={columns}
            data={filteredData}
            isLoading={loading}
            options={{
                padding: "dense",
                search: true,
                sorting: true,
                exportAllData: true,
                exportButton: {
                    csv: true,
                    pdf: false
                },
                cellStyle: {
                    padding: "2px 2px",
                    fontSize: "13px",
                },
                pageSize: 20,
                pageSizeOptions: [20],
            }}
            onRowClick={(event, rowData) => props.handleClick('/monitor/' + rowData.project)}
            components={{
                Header: props => {
                    return (
                        <TableHead>
                            <TableRow>
                                <StyledHeaderCell>Project</StyledHeaderCell>
                                <StyledHeaderCell>Description</StyledHeaderCell>
                                <StyledHeaderCell>Customer</StyledHeaderCell>
                                <StyledHeaderCell>Total PO's</StyledHeaderCell>
                                <StyledHeaderCell>Updated</StyledHeaderCell>
                                <StyledHeaderCell colSpan={3}>Actuals</StyledHeaderCell>
                                <StyledHeaderCell colSpan={2}>EtC €</StyledHeaderCell>
                                <StyledHeaderCell>Total cost</StyledHeaderCell>
                                <StyledHeaderCell>Comment</StyledHeaderCell>
                            </TableRow>
                            <TableRow>
                                <StyledHeaderCell>Name</StyledHeaderCell>
                                <StyledHeaderCell />
                                <StyledHeaderCell />
                                <StyledHeaderCell />
                                <StyledHeaderCell />
                                <StyledHeaderCell>Hours (hr)</StyledHeaderCell>
                                <StyledHeaderCell colSpan={2}>Mtrl €</StyledHeaderCell>
                                <StyledHeaderCell>Hours (hr)</StyledHeaderCell>
                                <StyledHeaderCell>Mtrl €</StyledHeaderCell>
                                <StyledHeaderCell>Forecast</StyledHeaderCell>
                                <StyledHeaderCell />
                            </TableRow>
                            <TableRow>
                                <StyledHeaderCell />
                                <StyledHeaderCell />
                                <StyledHeaderCell />
                                <StyledHeaderCell />
                                <StyledHeaderCell align='center'>G/R</StyledHeaderCell>
                                <StyledHeaderCell align='center'>Total</StyledHeaderCell>
                                <StyledHeaderCell align='center'>AFAS</StyledHeaderCell>
                                <StyledHeaderCell align='center'>Fledge</StyledHeaderCell>
                                <StyledHeaderCell align='center'>Total</StyledHeaderCell>
                                <StyledHeaderCell />
                                <StyledHeaderCell />
                                <StyledHeaderCell />
                            </TableRow>
                        </TableHead>
                    );
                },
            }}
        />
    )
}
export default withStyles(styles)(MonitorOverviewTable);
